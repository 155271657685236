.App {
    padding: 10px 100px;
}

@media only screen and (max-width: 600px) {
    .App {
        padding: 5px 30px;
    }
}


.SpinnerContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.Spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

